// extracted by mini-css-extract-plugin
export var header = "Header-module--header--1Qt4T";
export var container = "Header-module--container--3KhaC";
export var search = "Header-module--search--ucYp8";
export var linksContainer = "Header-module--linksContainer--WRDuP";
export var active = "Header-module--active--1N9yZ";
export var primary = "Header-module--primary--yIDLc";
export var logo = "Header-module--logo--RPmCm";
export var logoImg = "Header-module--logoImg--f8A17";
export var logoText = "Header-module--logoText--EwM4-";
export var links = "Header-module--links--3cZB-";
export var link = "Header-module--link--4ebYS";